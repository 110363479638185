import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import ImageDisplay from "../basics/imageDisplay/imageDisplay";
import "./priceCard.scss";

const PriceCard = ({ image, title, subtitle, price, length }) => {
  const imageData = getImage(image);
  return (
    <div className="price-card">
      <div className="price-card-wrapper">
        <div className="price-card-title-box">
          {imageData && <ImageDisplay image={imageData} />}
          <p>{title}</p>
        </div>
        <p className="price-card-subtitle">{subtitle}</p>
        <div className="price-card-info">
          <p className="price-card-length">{length} Minutes</p>
          <p className="price-card-price">£ {price}</p>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;

export const PriceCardContainer = ({ children }) => (
  <div className="price-card-container">{children}</div>
);
