import * as React from "react";
import { graphql } from "gatsby";
import PageContent from "../../../components/layout/content";
import Layout from "../../../components/layout/layout";
import PageHeader from "../../../components/layout/header/pageHeader";
import { useMemo } from "react";
import PriceCard, {
  PriceCardContainer,
} from "../../../components/priceCard/priceCard";
import {
  FrameBlock100,
  FrameSectionRow,
  FrameSectionTitle,
} from "../../../components/basics/frame/frameBlocks";
import "../../css/pricingPage.scss";
import "../../../theme/style.scss";

const PricingPage = ({ data }) => {
  const pageData = useMemo(() => data.contentfulOldal, [data]);
  const contents = useMemo(
    () => pageData?.tartalomTpus?.serviceCategory || [],
    [pageData],
  );

  const categories = useMemo(() => {
    const tempArray = [];
    contents.forEach(({ serviceCategory }) => {
      if (
        serviceCategory?.oldal?.[0]?.cm &&
        !tempArray.includes(serviceCategory?.oldal?.[0]?.cm)
      ) {
        tempArray.push(serviceCategory?.oldal[0]?.cm);
      }
    });
    return tempArray;
  }, [contents]);
  //console.log(categories,contents)
  return (
    <Layout>
      <PageHeader pageData={pageData} />
      <PageContent>
        <div className="price-blur-blob"></div>
        {categories.map((category) => (
          <React.Fragment key={category}>
            <FrameSectionTitle className="price-block">
              {category}
            </FrameSectionTitle>
            <FrameSectionRow>
              <FrameBlock100>
                <PriceCardContainer>
                  {contents
                    .filter(
                      (priceCard) =>
                        priceCard?.serviceCategory?.oldal?.[0]?.cm === category,
                    )
                    .map((card) => (
                      <PriceCard
                        title={card?.title}
                        price={card?.price}
                        length={card?.length}
                        subtitle={card?.serviceCategory?.oldal?.[0]?.cm}
                        image={card?.serviceCategory?.ikon}
                      />
                    ))}
                </PriceCardContainer>
              </FrameBlock100>
            </FrameSectionRow>
          </React.Fragment>
        ))}
      </PageContent>
    </Layout>
  );
};
//hozzá kell adni az összes építőblock típust
export const query = graphql`
  query pricing($contentfulId: String) {
    contentfulOldal(contentful_id: { eq: $contentfulId }) {
      slug
      cm
      alcm
      kategria {
        slug
      }
      tartalomTpus {
        ... on ContentfulPricingTartalomTipus {
          serviceCategory {
            title
            contentful_id
            length
            serviceCategory {
              ikon {
                gatsbyImageData(layout: CONSTRAINED)
              }
              oldal {
                cm
              }
            }
            price
          }
          header {
            bortkp {
              gatsbyImageData(layout: CONSTRAINED)
              url
            }
            lers
            title
            primaryCta {
              ... on ContentfulCtaGomb {
                ctaGombLink {
                  ... on ContentfulOldal {
                    slug
                    kategria {
                      slug
                    }
                  }
                  ... on ContentfulOldalKategoria {
                    slug
                  }
                }
                ctaGombSzveg
                externalCtaGombLink
              }
            }
            secondaryCta {
              ... on ContentfulCtaGomb {
                ctaGombLink {
                  ... on ContentfulOldal {
                    slug
                    kategria {
                      slug
                    }
                  }
                  ... on ContentfulOldalKategoria {
                    slug
                  }
                }
                ctaGombSzveg
                externalCtaGombLink
              }
            }
          }
        }
      }
    }
  }
`;

export default PricingPage;
